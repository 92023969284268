.headerContainer {
  border-bottom: 0px !important;
}
.formControl {
  padding: 50px !important;
  width: 100%;
  padding-left: 70px !important;
}
.HeadingTitle {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}
.salescheckboxfield {
  display: flex;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 30px;
}
.checkboxheadings {
  display: block;
  margin-left: 25px;
}
.checkboxTextbox > div > input {
  width: 350px !important;
}
.checkboxtitle {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px !important;
}
/* i-button css */
.flexDiv {
  display: flex;
  align-items: center;
}
/* i-button div */
.checkboxIcon {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px !important;
  cursor: pointer;
}
/* info modal */
.infoModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  padding-bottom: 20px;
}
.infoModalHeader {
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}
.infoModalHeaderTitle {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex: 5;
  justify-content: flex-start;
  align-items: center;
}
.infoModalHeaderCloseBtn {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.infoModalContent {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
}
.infoModalContentCard {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 20px;
}
.infoModalContentImage {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 20%;
}
.infoModalContentText {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  padding-left: 30px;
  padding-top: 10px;
  width: 80%;
}
.infoModalContentCaption {
  font-size: 0.9rem;
  font-weight: 500;
}
.infoModalContentInfo {
  font-size: 0.8rem;
  font-weight: 300;
  padding-top: 5px;
  padding-bottom: 5px;
}
.infoModalContentLink {
  font-size: 0.8rem;
  font-weight: 400;
  color: #007bff;
  text-decoration: underline;
}
.salescheckboxfield > div > span > span {
  color: #6c5dd3 !important;
}
.salesbutton {
  background-color: #6c5dd3 !important;
  color: white !important;
  height: 57px;
  padding: 0 38px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: -20px !important;
}
.salebuttonContainer {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.textBoxhandle {
  display: flex;
  margin-top: 40px !important;
}
.salescheckContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.salesDataContainer {
  display: flex;
}
.salesImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.learnmore {
  text-decoration: underline;
  font-size: 14px;
}
.salesformContainer {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  padding: 25px 50px;
  margin-bottom: 30px;
}
.formdiv {
  padding: 10px 0px;
  width: 100%;
}
.inputbox {
  display: flex;
  margin-top: 15px !important;
}
.stepsBlock {
  padding: 20px 0px 0px 0px;
}
.darkBlock {
  background-color: rgba(218, 218, 218, 0.2);
  border-radius: 5px;
  padding: 5px;
}
.list {
  margin: 0px;
  padding-inline-start: 25px;
}
.actionBtn {
  margin-left: 20px;
}
.firstAction {
  cursor: pointer;
  margin-right: 20px;
}
.textDanger {
  color: red;
  cursor: pointer;
}
.pullRight {
  float: right;
  margin-top: 20px;
}
.primaryBtn {
  background-color: #6c5dd3 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
}
.textMuted {
  font-size: 16px;
  color: #666666;
}
.mediumBoldText {
  font-size: 18px;
  font-weight: 500;
}

.errorMsg {
  color: red;
  font-size: 12px;
}

.guideIconStyle {
  color: #493ab1;
}
@media screen and (max-width: 780px) {
  .formControl {
    padding: 20px !important;
  }
  .HeadingTitle {
    font-size: 20px;
  }
  .salescheckboxfield {
    padding: 15px;
  }
  .checkboxheadings {
    display: block;
    margin-left: 10px;
  }
  .checkboxTextbox {
    width: 100%;
    display: block;
  }
  .textBoxhandle {
    display: block;
    margin-top: 40px !important;
  }
  .checkboxTextbox > div > input {
    width: 100% !important;
  }
  .checkboxtitle {
    font-size: 18px;
  }
  .salesbutton {
    background-color: #6c5dd3 !important;
    color: white !important;
    height: 40px;
    padding: 0 20px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    margin-left: 0 !important;
    margin-top: 20px !important;
    float: right;
  }
  .salebuttonContainer {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
  }
  /* i-button css */
  .infoModalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    outline: none;
    padding-bottom: 20px;
  }
  .infoModalContentCard {
    display: flex;
    padding-bottom: 10px;
  }
  .infoModalContentText {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    padding-left: 0px;
    padding-top: 10px;
    width: 100%;
  }
  .infoModalHeaderTitle {
    font-size: 0.8rem;
  }
  .infoModalContentImage {
    width: 100%;
  }
  .infoModalContentText {
    width: 100%;
  }
  .infoModalContentCaption {
    font-size: 0.8rem;
  }
  .infoModalContentInfo {
    font-size: 0.7rem;
  }
  .infoModalContentLink {
    font-size: 0.7rem;
  }
  .salesformContainer {
    padding: 15px 25px;
  }
  .inputbox {
    display: block;
  }
}
